import Headroom from "headroom.js";
var mobileMenuToggle, mobileToggleChildren, scrollTop;

const header = document.querySelector("#header");
const headroom = new Headroom(header);
headroom.init();

mobileMenuToggle = function () {
  "use strict";
  $('.mobile-menu-toggle').on('click', function () {
    var mobileNavIsOpen = $('body').hasClass("mobileNav-open");
    if (mobileNavIsOpen) {
      $('body').removeClass("mobileNav-open");
      window.scroll(0, scrollTop);
    } else {
      scrollTop = $(window).scrollTop();
      $('body').addClass("mobileNav-open");
    }
  });
};

mobileToggleChildren = function () {
  "use strict";
  var toggleLink;
  toggleLink = $('.mobile-menu .toggle-children');
  return toggleLink.on('click', function (e) {
    var $link, targetId;
    e.preventDefault();
    $link = $(this);
    targetId = $link.attr('href');
    $link.toggleClass('showing-children');
    $(targetId).toggleClass('show-children');
    return false;
  });
};

var recipeAnchorNav = function () {
  const recipeAnchorNav = document.querySelector(".single-recipe__anchor-navigation");
  const headroom = new Headroom(recipeAnchorNav);
  headroom.init();
};


var searchAnchorNav = function () {
  const searchAnchorNav = document.querySelector(".searchPage__tabs .tabs");
  const headroom = new Headroom(searchAnchorNav);
  headroom.init();
};

// Get all sections that have an ID defined
const sections = document.querySelectorAll("div[id^=ankkuri-]");

// Add an event listener listening for scroll
window.addEventListener("scroll", navHighlighter);

function navHighlighter() {

  // Get current scroll position
  let scrollY = window.pageYOffset;

  // Now we loop through sections to get height, top and ID values for each
  sections.forEach((current) => {

    const contentSection = current;
    const sectionHeight = contentSection.nextElementSibling.offsetHeight;
    const sectionTop = contentSection.offsetTop;
    let sectionId = current.getAttribute("id");

    /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */

    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
      document.querySelector(".single-recipe__anchor-navigation a[href*=" + sectionId + "]").classList.add('active-anchor');
    } else {
      document.querySelector(".single-recipe__anchor-navigation a[href*=" + sectionId + "]").classList.remove('active-anchor');
    }

  });
}


$(function () {
  "use strict";
  mobileMenuToggle();
  if (document.querySelector(".single-recipe__anchor-navigation")) {
    recipeAnchorNav();
  }
  if (document.querySelector(".searchPage__tabs .tabs")) {
    searchAnchorNav();
  }
  return mobileToggleChildren();
});
